import { PublicClientApplication } from '@azure/msal-browser';

// authConfig.js
export const msalConfig = {
  auth: {
    clientId: 'b6b46294-6f28-49d0-b858-383e246970c5', // Application (client) ID from the Azure portal
    authority: 'https://login.microsoftonline.com/thriwe1outlook.onmicrosoft.com',
    redirectUri: `${window.location.origin}/`, // Dynamic redirect URI based on the current environment
  },
  cache: {
    cacheLocation: 'localStorage', // Enables the token to be stored in local storage
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: ['openid', 'profile', 'User.Read'],
};

export const msalInstance = new PublicClientApplication(msalConfig);
