import React, { useEffect, useState } from 'react';
import { BreadCrumb } from '../../Common';
import { Container } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GET_BOOKING_SEARCH_LIST, GET_BOOKING_SEARCH_LIST_EMPTY } from '../../../store/actions';
import Filter from './Components/Filters/Filter';
import Table from './Components/Tables/Table';
import { toast } from 'react-toastify';
import { Loader } from '../../Common';

const BookingSearch = () => {
  const titleName = 'Booking Search';
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [currentlyAppliedFilters, setCurrentlyAppliedFilters] = useState(null);

  const dispatch = useDispatch();
  const history = useHistory();
  const [isDetailsPage, setIsDetailsPage] = useState(true);
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/dashboard`);
    } else if (title === titleName) {
      setIsDetailsPage(false);
    }
  };

  const { bookings, bookingsLoading, bookingsError, bookingsMessage } = useSelector((state: any) => ({
    bookings: state?.BookingsSearch?.data,
    bookingsLoading: state?.BookingsSearch?.loading,
    bookingsError: state?.BookingsSearch?.error,
    bookingsMessage: state?.BookingsSearch?.error,
  }));

  useEffect(() => {
    if (bookingsError) {
      toast.error('There are no records found', {
        position: 'top-right',
        hideProgressBar: false,
        progress: undefined,
        toastId: '',
      });
    }
  }, [bookingsError]);

  // useEffect(() => {
  //   dispatch({
  //     type: GET_BOOKING_SEARCH_LIST,
  //     payload: {
  //       urlParam: {
  //         pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
  //         sortOn: 'updatedAt',
  //       },
  //       data: {
  //         couponCode: 'UBER500076',
  //         membershipId: 'QzDq3SFSTE',
  //         projectCode: 'CREDILIO_BENEFITS',
  //         supportPanel: 'APIGATEWAY',
  //       },
  //     },
  //   });

  //   return () => {
  //     dispatch({
  //       type: GET_BOOKING_SEARCH_LIST_EMPTY,
  //     });
  //   };
  // }, [dispatch]);

  const filterSubmitHandler = filters => {
    if (filters?.filter) {
      setCurrentPageNumber(0);
      setCurrentlyAppliedFilters(filters);

      dispatch({
        type: GET_BOOKING_SEARCH_LIST,
        payload: {
          urlParam: {
            pageIndex: 0,
            sortOn: filters?.sortOn ?? 'updatedAt',
          },
          data: filters,
        },
      });
    } else {
      setCurrentlyAppliedFilters(filters);
    }
  };

  return (
    <div className="page-content">
      {bookingsLoading && <Loader />}
      <Container fluid className="position-relative">
        {isDetailsPage && (
          <>
            <BreadCrumb title={titleName} navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, titleName]} />
            <Filter filterHandler={filterSubmitHandler} appliedFilters={currentlyAppliedFilters} />

            <Table data={bookings?.results} totalData={bookings?.count} pageNumber={currentPageNumber} appliedFilters={currentlyAppliedFilters} />
          </>
        )}
      </Container>
    </div>
  );
};

export default BookingSearch;
