import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Modal, ModalBody, ModalHeader, Input, Label, Col, Row } from 'reactstrap';
import { Constant } from '../../../../../../../Helpers/constant';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';
import {
  GET_CLIENT_PERMISSION,
  GET_CLIENT_PERMISSION_FOR_USER,
  UPDATE_CLIENT_PERMISSION,
  UPDATE_CLIENT_PERMISSION_EMPTY,
} from '../../../../../../../../store/actions';
import { Loader } from '../../../../../../../Common';

const User = ({ selectedUser, onCloseHandler }) => {
  const dispatch = useDispatch();

  const [modal_varying1, setmodal_varying1] = useState(true);
  const [isPermissionState, setIsPermissionState] = useState({}); // Default to an empty object
  const [loading, setLoading] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Permission
  const { permissions, permissionLoading } = useSelector((state: any) => ({
    permissions: state?.GetClientPermission?.data,
    permissionLoading: state?.GetClientPermission?.loading,
  }));

  // Get User Permission
  const { getUserPermission, getUserPermissionLoading } = useSelector((state: any) => ({
    getUserPermission: state?.GetClientPermissionForUser?.data,
    getUserPermissionLoading: state?.GetClientPermissionForUser?.loading,
  }));

  // Get User Permission
  const { updateClientPermission, updateClientPermissionLoading } = useSelector((state: any) => ({
    updateClientPermission: state?.UpdateClientPermission?.data,
    updateClientPermissionLoading: state?.UpdateClientPermission?.loading,
  }));

  useEffect(() => {
    if (permissions && getUserPermission) {
      const selectedUserPermission = getUserPermission?.map(permission => permission?.code);
      // Create an initial state where permissions matching `userPermission` are true
      const initialState = permissions.reduce((acc, perm) => {
        acc[perm.code] = selectedUserPermission.includes(perm.code); // Set to true if `code` exists in `userPermission`
        return acc;
      }, {});

      setIsPermissionState(initialState);
    }
  }, [permissions, getUserPermission]);

  console.log('Selected User', selectedUser);

  useEffect(() => {
    dispatch({
      type: GET_CLIENT_PERMISSION,
    });
    dispatch({
      type: GET_CLIENT_PERMISSION_FOR_USER,
      payload: {
        urlParams: {
          userId: selectedUser?.userId,
        },
      },
    });
  }, [selectedUser]);

  useEffect(() => {
    if (updateClientPermission !== null) {
      onCloseHandler(false);
      setmodal_varying1(!modal_varying1);
      dispatch({
        type: UPDATE_CLIENT_PERMISSION_EMPTY,
      });
    }
  }, [updateClientPermission]);

  useEffect(() => {
    if (permissionLoading || getUserPermissionLoading || updateClientPermissionLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [permissionLoading, getUserPermissionLoading, updateClientPermissionLoading]);

  const handleCheckboxChange = code => {
    setIsPermissionState(prevState => ({
      ...prevState,
      [code]: !prevState[code],
    }));
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    const selectedPermissions = Object.keys(isPermissionState).filter(key => isPermissionState[key]) ?? [];

    dispatch({
      type: UPDATE_CLIENT_PERMISSION,
      payload: {
        data: {
          roleCodes: selectedPermissions,
          userIds: [selectedUser?.userId],
          action: 'attach',
        },
      },
    });
  };

  const tog_varying1 = () => {
    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  return (
    <Modal
      isOpen={modal_varying1}
      toggle={() => {
        tog_varying1();
      }}
      id="exampleModal"
      scrollable={true}
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Edit User
        </h5>
        <Button
          type="button"
          onClick={() => {
            tog_varying1();
          }}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>
      {loading && <Loader zIndex={true} />}

      <ModalBody>
        <form>
          <div className="mb-3">
            <Col md={12}>
              <div>
                <Label htmlFor="name" className="form-label">
                  Name <span className="lbl_mandatory">*</span>
                </Label>
                <Input id="name" name="name" className="form-control" value={selectedUser?.firstName} disabled />
              </div>
            </Col>
          </div>

          <div className="mb-3">
            <Col md={12}>
              <div>
                <Label htmlFor="email" className="form-label">
                  Email <span className="lbl_mandatory">*</span>
                </Label>
                <Input id="email" name="email" type="text" className="form-control" value={selectedUser?.email} disabled />
              </div>
            </Col>
          </div>

          <div className="mb-3">
            <Label htmlFor="name" className="form-label">
              User Rights
            </Label>
            <Row>
              {permissions &&
                permissions?.length > 0 &&
                permissions.map(permission => (
                  <Col md={4} key={permission.id}>
                    <div className="d-flex align-items-center">
                      <div className="d-inline-block">
                        <Input
                          className="form-check-input form-switch-sm"
                          type="checkbox"
                          id={permission.code}
                          name={permission.code}
                          checked={isPermissionState[permission.code]}
                          onChange={() => handleCheckboxChange(permission.code)}
                        />
                      </div>
                      <span>&nbsp;{permission.name.replace('ADIB_', '')}</span>
                    </div>
                  </Col>
                ))}
            </Row>
          </div>
        </form>
      </ModalBody>
      <div className="modal-footer d-flex justify-content-center">
        <Button className="overlay-disable" color="primary" onClick={submitHandler}>
          Submit
        </Button>
      </div>
    </Modal>
  );
};

export default User;
