import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { BreadCrumb } from '../../../../../Common';
import UsersList from '../../../../UserManagement/Components/Users';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { GET_CLIENT_USER } from '../../../../../../store/actions';
import { Link } from 'react-router-dom';
import { AsyncPaginate } from 'react-select-async-paginate';
import { baseUrl, customHeaders } from '../../../../../Config';
import { Constant } from '../../../../../Helpers/constant';
import User from './Components/Modals/User';

const UserManagementAdib = () => {
  const skip = useRef(0);
  const dispatch = useDispatch();

  const [clientUsers, setClientUsers] = useState([]);
  const [typedAdminName, setTypedAdminName] = useState(null);
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Client Users
  const { users, usersLoading } = useSelector((state: any) => ({
    users: state?.GetClientUser?.data,
    usersLoading: state?.GetClientUser?.loading,
  }));

  console.log('users', users);

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    dispatch({
      type: GET_CLIENT_USER,
      payload: {
        urlParams: {
          pageIndex: skip.current,
          pageSize: 10,
          organization_id: 12,
        },
      },
    });
  }, [dispatch]);

  useEffect(() => {
    if (users?.admins?.length > 0) {
      const newAdminUsers = users?.admins?.map(user => {
        return {
          ...user,
          userShortName: makeUserShortName(user?.firstName, user?.lastName),
        };
      });
      setClientUsers([...clientUsers, ...newAdminUsers]);
    }
  }, [users]);

  useEffect(() => {
    const list = document.querySelectorAll('.team-list');
    const buttonGroups = document.querySelectorAll('.filter-button');
    for (let i = 0; i < buttonGroups.length; i++) {
      buttonGroups[i].addEventListener('click', onButtonGroupClick);
    }

    function onButtonGroupClick(event) {
      if (event.target.id === 'list-view-button' || event.target.parentElement.id === 'list-view-button') {
        document.getElementById('list-view-button').classList.add('active');
        document.getElementById('grid-view-button').classList.remove('active');
        list.forEach(function (el) {
          el.classList.add('list-view-filter');
          el.classList.remove('grid-view-filter');
        });
      } else {
        document.getElementById('grid-view-button').classList.add('active');
        document.getElementById('list-view-button').classList.remove('active');
        list.forEach(function (el) {
          el.classList.remove('list-view-filter');
          el.classList.add('grid-view-filter');
        });
      }
    }
  }, []);

  /* ---------------------------- GET CLIENT USERS ---------------------------- */
  const fetchClientUsers = () => {
    skip.current += 10;
    dispatch({
      type: GET_CLIENT_USER,
      payload: {
        urlParams: {
          pageIndex: skip.current,
          pageSize: 10,
          organization_id: 12,
        },
      },
    });
  };

  /* ---------------------------- ON SELECT USER ---------------------------- */
  const onSelectAdminUser = event => {
    setClientUsers([
      {
        ...event?.completeData,
        userShortName: makeUserShortName(event?.completeData?.firstName, event?.completeData?.lastName),
      },
    ]);
    setTypedAdminName(event);
  };

  /* ---------------------------- SHORT USERNAME HANDLER ---------------------------- */
  const makeUserShortName = (firstName: string, lastName: string) => {
    return `${firstName[0] ?? ''}${lastName[0] ?? ''}`;
  };

  /* ---------------------------- TYPED USER HANDLER ---------------------------- */
  async function loadAdminUsers(search) {
    const response = await fetch(`${baseUrl}/admin/v1/organization-admins?email=${search}&organization_id=${12}`, {
      method: `GET`,
      headers: new Headers({
        'Project-Code': `ADMIN_ACCESS`,
      }),
    });
    const usersResponse = await response.json();

    return {
      options: usersResponse?.map((data, index) => {
        return {
          value: data.id,
          label: `${data.firstName ?? ''} ${data.lastName ?? ''}`,
          completeData: data,
        };
      }),
      hasMore: false,
    };
  }

  const removeSearchedUser = () => {
    setClientUsers([]);
    setTypedAdminName(null);
    skip.current = 0;

    dispatch({
      type: GET_CLIENT_USER,
      payload: {
        urlParams: {
          pageIndex: skip.current,
          pageSize: 10,
          organization_id: 12,
        },
      },
    });
  };

  return (
    <div className="page-content">
      {isUserModalOpen && <User selectedUser={selectedUser} onCloseHandler={() => setIsUserModalOpen(false)} />}
      <Container fluid className="position-relative">
        <BreadCrumb title="Dump Download" navigationby={() => {}} navigation={[`Dashboard`, `Users`]} />

        <Card>
          <CardBody>
            <Row className="g-2">
              <Col sm={4}>
                <AsyncPaginate
                  debounceTimeout={500}
                  value={typedAdminName}
                  loadOptions={loadAdminUsers}
                  onChange={e => onSelectAdminUser(e)}
                  isMulti={false}
                  closeMenuOnSelect={true}
                  noOptionsMessage={() => 'No results found'}
                  cacheUniqs={[['code']]}
                  placeholder="Enter Name..."
                />
              </Col>

              {typedAdminName && (
                <Col sm={4}>
                  <Button
                    color="danger"
                    id="grid-view-button"
                    className="btn btn-soft-danger nav-link btn-icon fs-14 active"
                    onClick={removeSearchedUser}
                  >
                    <i className="ri-close-fill"></i>
                  </Button>
                </Col>
              )}
            </Row>
          </CardBody>
        </Card>

        <Row>
          <Col lg={12}>
            <div>
              <InfiniteScroll dataLength={clientUsers?.length} next={fetchClientUsers} hasMore={typedAdminName === null ? true : false} loader>
                <Row className="team-list grid-view-filter">
                  {clientUsers?.map((adminUser, key) => (
                    <Col key={key}>
                      <Card className="team-box">
                        <div className="team-cover">
                          <img src={adminUser.backgroundImg} alt="" className="img-fluid" />
                        </div>
                        <CardBody className="p-4">
                          <Row className="align-items-center team-row">
                            <Col lg={4} className="col">
                              <div className="team-profile-img">
                                <div className="avatar-lg img-thumbnail rounded-circle flex-shrink-0">
                                  {adminUser.userImage != null ? (
                                    <img src={adminUser.userImage} alt="" className="img-fluid d-block rounded-circle" />
                                  ) : (
                                    <div className={'avatar-title rounded-circle bg-soft-' + adminUser.bgColor + ' text-' + adminUser.textColor}>
                                      {adminUser.userShortName}
                                    </div>
                                  )}
                                </div>
                                <div className="team-content">
                                  <h5 className="fs-16 mb-1">{adminUser.name}</h5>
                                  {/* <p className="text-muted mb-0">
                                                                    {adminUser.jobPosition}
                                                                </p> */}
                                  <p className="text-muted mb-0">{adminUser.email}</p>
                                </div>
                              </div>
                            </Col>
                            <Col lg={4} className="col">
                              <Row className="text-muted text-center">
                                <Col xs={12} className="border-end border-end-dashed border-start border-start-dashed">
                                  <h5 className="mb-1">{adminUser.projectCount}</h5>
                                  <p className="text-muted mb-0">Projects</p>
                                </Col>
                              </Row>
                            </Col>
                            <Col lg={2} className="col">
                              <div
                                className="text-center btn btn-light view-btn"
                                onClick={() => {
                                  setIsUserModalOpen(true);
                                  setSelectedUser(adminUser);
                                }}
                              >
                                Edit Permissions
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}

                  {usersLoading && (
                    <Col lg={12}>
                      <div className="text-center mb-3">
                        <Link to="#" className="text-success">
                          <i className="mdi mdi-loading mdi-spin fs-20 align-middle me-2"></i>
                          Loading
                        </Link>
                      </div>
                    </Col>
                  )}
                </Row>
              </InfiniteScroll>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default UserManagementAdib;
