import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Card, CardBody, Col, Form, Label, Row, CardFooter, Input } from 'reactstrap';

import { PreviewCardHeader } from '../../../../Common';
import { toast } from 'react-toastify';
import { GET_PROJECTS_LIST, GET_BOOKING_SEARCH_LIST_EMPTY } from '../../../../../store/application/actionType';

const Filter = props => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialState = {
    projectCode: '',
    membershipId: '',
    bookingId: '',
    couponCode: '',
    sortOn: 'updatedAt',
  };
  const [filter, setFilter] = useState(initialState);
  const [isFilterDisabled, setIsFilterDisabled] = useState(false);

  const { projectsList, projectsListLoading } = useSelector((state: any) => ({
    projectsList: state?.ProjectsList?.data,
    projectsListLoading: state?.ProjectsList?.loading,
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: GET_PROJECTS_LIST,
      payload: {},
    });

    return () => {};
  }, [dispatch]);

  useEffect(() => {
    if (props?.appliedFilters) {
      setFilter(props?.appliedFilters);
    }
  }, [props?.appliedFilters]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = null;

    switch (name) {
      case 'project-code':
        updatedState = {
          ...filter,
          projectCode: value,
        };
        setFilter(updatedState);
        validationCheck(updatedState);
        break;
      case 'voucher-code':
        updatedState = {
          ...filter,
          couponCode: value,
        };
        setFilter(updatedState);
        validationCheck(updatedState);
        break;

      case 'booking-id':
        updatedState = {
          ...filter,
          bookingId: value,
        };
        setFilter(updatedState);
        validationCheck(updatedState);
        break;

      case 'membership-id':
        updatedState = {
          ...filter,
          membershipId: value,
        };
        setFilter(updatedState);
        validationCheck(updatedState);
        break;

      case 'sortOn':
        updatedState = { ...filter, sortOn: value };
        setFilter(updatedState);
        validationCheck(updatedState);
        break;

      default:
      // do nothing
    }
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validationCheck = data => {};

  /* ---------------------------- ON SUBMIT HANDLER ---------------------------- */
  const filterSubmitHandler = () => {
    dispatch({
      type: GET_BOOKING_SEARCH_LIST_EMPTY,
    });

    if (!filter?.projectCode) {
      toast.error('Please select project', {
        position: 'top-right',
        hideProgressBar: false,
        progress: undefined,
        toastId: '',
      });
    } else if (!filter?.membershipId) {
      toast.error('Please enter membership id', {
        position: 'top-right',
        hideProgressBar: false,
        progress: undefined,
        toastId: '',
      });
    } else {
      if (filter?.bookingId || filter?.couponCode) {
        const project = projectsList?.results.find(item => item?.code == filter?.projectCode);
        props.filterHandler({ ...filter, supportPanel: project?.supportPanel, filter: true });
      } else {
        toast.error('Please enter either voucher code or booking id', {
          position: 'top-right',
          hideProgressBar: false,
          progress: undefined,
          className: 'toastLongContent',
          toastId: 'sdsddsds',
        });
      }
    }
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <PreviewCardHeader title="Booking Search" />
            <CardBody>
              <div className="live-preview">
                <Form>
                  <Row>
                    <Col md={3}>
                      <div className="mb-3">
                        <Label htmlFor="project-code" className="form-label">
                          Project List<span className="text-danger">*</span>
                        </Label>
                        <select
                          id="project-code"
                          name="project-code"
                          className="form-select"
                          value={filter?.projectCode}
                          onChange={inputChangeHandler}
                        >
                          <option value="">Select Project</option>
                          {projectsList?.results?.map((project, index) => (
                            <option key={index} value={project?.code}>
                              {project?.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="mb-3">
                        <Label htmlFor="membership-id" className="form-label">
                          Membership ID<span className="text-danger">*</span>
                        </Label>
                        <Input
                          id="membership-id"
                          name="membership-id"
                          type="text"
                          placeholder=""
                          className="form-control"
                          onChange={inputChangeHandler}
                          value={filter?.membershipId}
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="d-flex align-items-center gap-3">
                        <div className="mb-3 w-100">
                          <Label htmlFor="voucher-code" className="form-label">
                            Voucher Code
                          </Label>
                          <Input
                            id="voucher-code"
                            name="voucher-code"
                            type="text"
                            placeholder=""
                            className="form-control"
                            onChange={inputChangeHandler}
                            value={filter?.couponCode}
                          />
                        </div>
                        <div>OR</div>
                        <div className="mb-3  w-100">
                          <Label htmlFor="booking-id" className="form-label">
                            Booking ID
                          </Label>
                          <Input
                            id="booking-id"
                            name="booking-id"
                            type="text"
                            placeholder=""
                            className="form-control"
                            onChange={inputChangeHandler}
                            value={filter?.bookingId}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </CardBody>

            <CardFooter>
              <Row>
                <Col md={12}>
                  <div className="text-end">
                    <button type="submit" className="btn btn-primary mx-3 overlay-disable" onClick={filterSubmitHandler} disabled={isFilterDisabled}>
                      Apply
                    </button>
                  </div>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Filter;
